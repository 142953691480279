@import "./../variables/color";
$screen-small: 576px;
$screen-medium: 768px;
$screen-large: 992px;
$screen-xlarge: 1200px;
$screen-xxlarge: 1400px;

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex($direction: row, $justify: center, $align: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}
@mixin card {
    background-color: $color-white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

@mixin card {
    background-color: $color-white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

@mixin screenSmall{
    @media (min-width:$screen-small) {
        @content;
    }
}
@mixin screenMedium {
    @media (min-width: $screen-medium) {
        @content;
    }
}

@mixin screenLarge {
    @media (min-width: $screen-large) {
        @content;
    }
}

@mixin screenXL {
    @media (min-width: $screen-xlarge) {
        @content;
    }
}

@mixin screenXXL {
    @media (min-width: $screen-xxlarge) {
        @content;
    }
}
