

@font-face {
    font-family: "Comfortaa";
    src: url("./../../font/Comfortaa-Light.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "Comfortaa";
    src: url("./../../font/Comfortaa-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Comfortaa";
    src: url("./../../font/Comfortaa-Medium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "Comfortaa";
    src: url("./../../font/Comfortaa-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Comfortaa";
    src: url("./../../font/Comfortaa-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "SF";
    src: url("./../../font/SFPRODISPLAYULTRALIGHTITALIC.OTF") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "SF";
    src: url("../../../assets/font/SFPRODISPLAYREGULAR.OTF") format("truetype");
}
@font-face {
    font-family: "SF";
    src: url("./../../font/SFPRODISPLAYMEDIUM.OTF") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "SF";
    src: url("./../../font/SFPRODISPLAYMEDIUM.OTF") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "SF";
    src: url("./../../font/SFPRODISPLAYBOLD.OTF") format("truetype");
    font-weight: 700;
}


