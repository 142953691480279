@use "@angular/material" as mat;
@import "./assets/scss/variables/variables.scss";
@include mat.core();
$md-mcgpaletteprimary: (
  50: #e3f4e7,
  100: #b8e4c3,
  200: #89d29c,
  300: #5ac074,
  400: #36b356,
  500: #13a538,
  600: #119d32,
  700: #0e932b,
  800: #0b8a24,
  900: #067917,
  A100: #a8ffb1,
  A200: #75ff83,
  A400: #42ff55,
  A700: #28ff3e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$md-paletteaccent: (
  50: #f4f8e3,
  100: #e3eeb9,
  200: #d1e38b,
  300: #bed75d,
  400: #b0cf3a,
  500: #a2c617,
  600: #9ac014,
  700: #90b911,
  800: #86b10d,
  900: #75a407,
  A100: #efffd0,
  A200: #deff9d,
  A400: #cdff6a,
  A700: #c4ff51,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);
$md-palettewarn: (
  50: #fceaea,
  100: #f7cccc,
  200: #f2aaaa,
  300: #ec8787,
  400: #e86e6e,
  500: #e45454,
  600: #e14d4d,
  700: #dd4343,
  800: #d93a3a,
  900: #d12929,
  A100: #ffffff,
  A200: #ffdddd,
  A400: #ffaaaa,
  A700: #ff9090,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$nobis-autogestion-primary: mat.define-palette($md-mcgpaletteprimary);
$nobis-autogestion-accent: mat.define-palette($md-paletteaccent);
$nobis-autogestion-warn: mat.define-palette($md-palettewarn);

$nobis-autogestion-theme: mat.define-light-theme(
  (
    color: (
      primary: $nobis-autogestion-primary,
      accent: $nobis-autogestion-accent,
      warn: $nobis-autogestion-warn
    )
  )
);

@include mat.all-component-themes($nobis-autogestion-theme);

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "SF";
  color: $color-grey-4;
  background-color: #f2f2f2;
}

a {
  color: $color-green-2;
  text-decoration: underline;
  cursor: pointer;
}
h1 {
  font-size: 25px;
  font-family: Comfortaa;
  color: $color-grey-4;
  margin-bottom: 26px;
}

h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #ffff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
mat-hint {
  color: $color-grey-4;
  font-size: 12px;
}
mat-error {
  font-size: 12px;
}
p {
  margin: 0;
  padding: 0;
}
.mat-mdc-radio-button .mdc-form-field {
  color: $color-grey-4;
  font-size: 17px;
}
.mat-mdc-checkbox .mdc-form-field {
  color: $color-grey-4;
  font-size: 17px;
}
.gm-style-iw-chr{
  display: none !important;
}

.mt-4 {
  margin-top: 40px;
  display: block;
}

.mb-4 {
  margin-bottom: 40px;
  display: block;
}

.hide-mobile {
  display: none;

  @include screenSmall {
    display: inline-block;
  }
}
