$color-white: #fff;
$color-grey-0: #f2f2f2;
$color-grey-1: #dadada;
$color-grey-2: #ccc;
$color-grey-3: #999999;
$color-grey-4: #666666;
$color-grey-5: #333333;
$color-green-3: #a2c617;
$color-green-2: #13a538;
$color-violet-7: #883880;
$color-blue-6: #485793;

$color-brown: #714a46;
$color-beige: #d9b091;

$color-warn: #e45454;
$color-success: #52b7a0;
